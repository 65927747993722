<template>
  <el-dialog :title="title" :visible.sync="isVisible" :before-close="handleClose" :updateId="updateId">
    <el-form ref="form" :model="userData" :rules="rules">
      <el-form-item label="用户名" prop="name">
        <el-input v-model="userData.name" placeholder="用户名"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="pwd">
        <el-input v-model="userData.pwd" autocomplete="off"  placeholder="密码"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "UserAddUpdate",
  data(){
      return {
        userData:{
          name:"",
          pwd:"",
          id:0,
        },
        rules:{
          name:[
            { required: true, message: '请输入用户名', trigger: 'blur' },
          ],
          pwd:[
            { required: true, message: '请输入密码', trigger: 'blur' },
          ],
        },
      }
  },
  //定义接受父组件传来的数据
  props:{
    isVisible:Boolean,
    title:String,
    updateId:Number,
  },
  methods:{
    handleClose(){
      console.log("close")
       this.$emit("ChangeVisible",false)
    },
    handleSubmit(){
      //表单检验通过才提交
      this.$refs.form.validate((res)=>{
         if (res){
           //添加编辑区分
           if (this.userData.id!=0){
             this.$http({
               method: 'post',
               url: '/sys_user/update',
               data: this.userData,
             }).then(res=>{
               if (res.data.code==0) {
                 //关闭弹窗
                 this.handleClose()
                 //消息提示
                 this.$message.success("操作成功")
                 //子传父，调用父组件的query方法，重新加载数据
                 this.$emit("query")
               }
             }).catch(err=>{
               console.log(err)
             })
           }else{
             this.$http({
               method: 'post',
               url: '/sys_user/add',
               data: this.userData,
             }).then(res=>{
               if (res.data.code==0) {
                 //清空表单
                 this.$refs.form.resetFields()
                 //关闭弹窗
                 this.handleClose()
                 //消息提示
                 this.$message.success("操作成功")
                 //子传父，调用父组件的query方法，重新加载数据
                 this.$emit("query")
               }
             }).catch(err=>{
               console.log(err)
             })
           }
         }
      })
    }
  },
  watch:{
    updateId(val){
      this.$http({
        method: 'post',
        url: '/sys_user/detail',
        data: {
          id: val
        },
      }).then(res=>{
        if (res.data.code==0) {
          this.userData.name = res.data.data.name
          this.userData.pwd = res.data.data.pwd
          this.userData.id = res.data.data.id
        }
      }).catch(err=>{
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>

</style>
